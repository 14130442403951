.profile{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.profile img{
    border-radius:50%;
    border: 1px solid var(--divider-color);
}

.profile span{
    margin-left: 15px;
    color: var(--dark-primary-color);
    font-weight: bold;
    font-size:1.5em;
}