@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --dark-primary-color: #1976d2; /*background*/
  --default-primary-color: #2196f3; /*background*/
  --light-primary-color: #bbdefb; /*background*/
  --text-primary-color: #ffffff; /*color*/
  --accent-color: #607d8b; /*background*/
  --primary-text-color: #212121; /*color*/
  --secondary-text-color: #757575; /**/
  --divider-color: #eee; /*border-color*/
}

*{
  font-family: 'Roboto', sans-serif;
  margin:0;
  padding:0;
  box-sizing: border-box;

}

body{
  margin-top:25px;
  margin-left:10px;
  margin-right: 10px;
}

.App{
  max-width: 450px;
  width:100%;
  padding:0;
  margin:0 auto;
}

.hr{
  display: block;
  margin:10px auto;
  width: 80%;
  border-bottom: 1px solid var(--divider-color);
}