.btn {
    padding: 10px 25px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    transition: all 0.2s;
}

.btn-green {
    background-color: #fff;
    color: #39533c;
    border: 1px solid #75c778;
}

.btn-green:active {
    box-shadow: 0 0 0 5px #39533c;
    color: #75c778;
}

.btn-red {
    background-color: #fff;
    color: #533939;
    border: 1px solid #c77575;
}

.btn-red:active {
    box-shadow: 0 0 0 5px #533939;
    color: #c77575;
}

.timerContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.timerContainer .timer {
    font-weight: courier;
    font-size:20px;
    font-weight: bold;
}

.timerContainer .reset{
    max-width: 24px;
    vertical-align: middle;
    margin-left:15px;
    cursor: pointer;
}
