.buttons {
    width: 100%;
    margin: 25px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.buttons button {
    font-weight: bold;
    padding: 25px;
    margin-right: 10px;
    color: var(--primary-text-color);
    background-color: var(--divider-color);
    border: none;
    transition: all 0.9s;
    cursor: pointer;
}

.buttons button:active{
    box-shadow: 0 0 5px #111;
}

.buttons button:active,
.buttons .active {
    color: var(--light-primary-color);
    background-color: var(--primary-text-color);
}

/*Sheet*/

.sheet {
    width: 100%;
    margin-bottom: 25px;
    border-radius: 5px;
    border: 1px solid var(--divider-color);
    
    box-shadow: 5px 5px 11px 2px rgba(166,166,166,0.83);
}

.sheet:first-child {
    margin-top: 15px;
}

.sheet .sheetHeader {
    background-color: var(--divider-color);
    padding: 10px;
    color: var(--primary-text-color);
    font-size: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.sheet .sheetHeader img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.sheet .sheetBody {
    padding: 10px;
    transition: all .05s;
}

.sheet .sheetBody .grid {
    display: flex;
    flex-wrap: wrap;
}

.sheet .sheetBody .grid div {
    flex: 50%;
    margin-bottom: 10px;
}

.sheet .sheetBody img {
    max-width: 24px;
    vertical-align: middle;
}

.sheet .sheetBody p {
    font-size: 14px;
    margin-bottom: 10px;
}

.sheet .sheetBody .image-refer{
    width: 100%;
    max-width: 100%;
    height: auto;
    margin:15px 0;
}

.hidden {
    display: none;
}

.show {
    display: block;
}

.actions {
    margin: 10px 0;
    text-align: center;
}

.actions .btnConcluir {
    padding: 10px;
    border-radius: 3px;
    border: none;
    font-weight: bold;
    font-size: 18px;
    border: 1px solid var(--default-primary-color);
    color: var(--default-primary-color);
    max-width: 50%;
    width: 100%;
    text-transform: uppercase;
    cursor: pointer;
}

.actions .btnConcluir:active {
    background-color: #333333;
    color:  var(--default-primary-color);
}